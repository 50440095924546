/* eslint-disable no-console, template-curly-spacing */
import getProfile from '~/public/assets/javascripts/profile';
import mapiRequest from '~/public/assets/javascripts/mapi';
import storage from './storage';
// import handleMctRefresh from './mctRefresh';

const {
  isMemberPage,
} = window.HP.params;
const isUS = window.HP.params.edition === 'us';
const loyaltyTiers = ['tier1', 'tier2', 'tier2_app', 'tier3'];
const contributorRoles = ['contributor_monthly', 'contributor_annually', 'contributor_once', 'contributor_canceled'];
// const webAdFreeRoles = ['tier2', 'tier3'];

const isValidEmail = (email) => email && typeof email === 'string' && email.includes('@');

const getCookieValue = (name) => (
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ''
);

const fetchFPUserData = async (profileData) => {
  try {
    // expire fp.auth if present since the cookie is set when fetching the user data
    const fpCoookiePresent = document.cookie.includes('fp.auth');
    if (fpCoookiePresent) {
      // clear cookie before calling fp data
      document.cookie = 'fp.auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.huffpost.com;';
    }

    const clientSessionId = getCookieValue('bf-xdomain-session-uuid');
    const response = await fetch('/api/flip-pay/user', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: profileData.email,
        name: profileData.name?.given,
        lastName: profileData.name?.family,
        register: true,
        browser_session_id: clientSessionId,
      }),
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    console.error('Error trying to get flip pay user access token and data', error);
    return null;
  }
};

const setNonRecurringContributorStorage = (role, roleCreatedAt) => {
  const MONTHS_TO_EXPIRE = 12;
  const roleCreatedAtDate = new Date(roleCreatedAt);
  storage.set('hp-contributor', {
    expires: roleCreatedAtDate.setMonth(roleCreatedAtDate.getMonth() + MONTHS_TO_EXPIRE),
    role,
    roleCreatedAt,
  });
};

const hasXMonthsPassedSinceDate = (date, x) => {
  const startDate = new Date(date);
  const dateXMonthsAgo = new Date();
  dateXMonthsAgo.setMonth(dateXMonthsAgo.getMonth() - x);
  return dateXMonthsAgo >= startDate;
};

const hasContributorStorageExpired = (expiresAt) => {
  const expirationDate = new Date(expiresAt);
  return expirationDate < Date.now();
};

const fpUserTypeToMapiRole = (fpUserData) => {
  const { type, canceled } = fpUserData;
  let role = null;
  // means user did a monthly or annually contribution but then canceled, we give the contributor canceled role
  if ((type === 'monthly' || type === 'annually') && canceled) {
    role = 'contributor_canceled';
  } else if (type) {
    role = `contributor_${type}`;
  }
  return role;
};

const handleNonRecurringContributorStatus = (currentRole, roleCreatedAt, monthsToHide) => {
  let contributorData = storage.get('hp-contributor');
  if (!contributorData || contributorData.role !== currentRole) {
    setNonRecurringContributorStorage(currentRole, roleCreatedAt);
    contributorData = storage.get('hp-contributor');
  }
  // hide the modules if less than x months have passed since the role was created
  // else show the new messaging
  // is-contributor class is used to hide the contribute modules in front and entries
  if (!hasXMonthsPassedSinceDate(contributorData.roleCreatedAt, monthsToHide)) {
    document.body.classList.add('is-contributor');
  } else {
    document.body.classList.add(currentRole);
  }

  if (hasContributorStorageExpired(contributorData.expires)) {
    document.body.classList.add('non-contributor');
  }
};

const determineActionType = (subscriber, roles, currentLoyaltyTier, loyaltyTier, canceled, refunded, refundDate, tierEndDate, fpRole, endDate, membership) => {
  // if subscriber = false it means its a one time contribution, we only need to add the contributor_once role and only if not present already
  if (subscriber === false && !roles.includes('contributor_once')) {
    return 'one_time_contribution';
  }

  // monthly and annual plans that are ongoing (not canceled or refunded)
  if (subscriber === true && !canceled && !refunded && loyaltyTier) {
    if (!currentLoyaltyTier) {
      // we have a membership in profile but no profile means auth0update failed but mapi went trough, lets just upadte the loyaltyTier
      if (membership && membership.hasMembership) {
        return 'add_loyalty_tier';
      }
      // if user doesn't have currentLoyaltyTier it means membership was not created we should treat it as new
      return 'new';
    }

    // if currentLoyaltyTier is different from the one in fpUserData it means there was a membership update, we need to update the role and update membership
    if (currentLoyaltyTier && loyaltyTier && currentLoyaltyTier !== loyaltyTier) {
      return 'change';
    }
    // if both role and loyaltyTier are present we need to check the tierEndDate, if they are different we need to update the tierEndDate
    if (roles.includes(fpRole) && loyaltyTiers.includes(currentLoyaltyTier) && tierEndDate && new Date(tierEndDate).getTime() !== new Date(endDate).getTime()) {
      return 'renew';
    }
  }

  // if subscriber = true and canceled = true (last purchase was canceled)
  if (subscriber === true && (canceled || refunded)) {
    if (refunded && (!tierEndDate || (tierEndDate && new Date(tierEndDate) > new Date(refundDate)))) {
      return 'refund';
    }
    // role contributor_canceled doesn't exist, we need to update the role
    if (!roles.includes('contributor_canceled')) {
      return 'cancel';
    }
    // if role contributor_canceled is already there and user still has one of the loyalty_tiers and the tierEndDate has passed we need to remove the loyalty tier role
    if (roles.includes('contributor_canceled') && loyaltyTiers.includes(currentLoyaltyTier) && new Date(tierEndDate) < Date.now()) {
      return 'remove_loyalty_tier';
    }
  }

  return '';
};

const makeSyncMapiCall = async (bodyToPatch) => {
  try {
    await mapiRequest('user/profile/edit', { body: { ...bodyToPatch } }, { method: 'PATCH' });
    // if we don't have the mct cookie yet after a mapi patch we should call getProfile again to update the cookie
    const mctCookiePresent = document.cookie.includes('mct');
    if (!mctCookiePresent) {
      await getProfile(true);
    }
  } catch (error) {
    console.error('Error updating subscriber data:', error);
  }
};

// Calls FP api to get membership information and Syncs mapi roles with roles returned by flip pay if needed
// returns the role that was set in mapi
const syncMapi = async (fpUserData, profile) => {
  const { roles, tierEndDate, membership } = profile;
  const currentLoyaltyTier = roles.find((value) => loyaltyTiers.includes(value));
  const currentRole = roles.find((value) => contributorRoles.includes(value));

  if (fpUserData && fpUserData.data) {
    const {
      subscriber,
      type,
      canceled,
      createdAt,
      startDate,
      endDate,
      fpCustomerId,
      fpTransactionId,
      fpPreviousTransactionEndDate,
      loyaltyTier,
      refunded,
      refundDate,
    } = fpUserData.data;
    const fpRole = fpUserTypeToMapiRole(fpUserData.data);

    // check for differences to see if we need to update the profile
    const bodyToPatch = {};

    // Determine the action type
    const actionType = determineActionType(subscriber, roles, currentLoyaltyTier, loyaltyTier, canceled, refunded, refundDate, tierEndDate, fpRole, endDate, membership);
    const startDateAsDate = new Date(startDate);
    const endDateAsDate = new Date(endDate);
    const refundDateAsDate = refundDate ? new Date(refundDate) : new Date();
    const fpPreviousTransactionEndDateAsDate = new Date(fpPreviousTransactionEndDate);
    const startDateTimestamp = Math.floor(startDateAsDate.getTime() / 1000);
    const endDateTimestamp = Math.floor(endDateAsDate.getTime() / 1000);
    const fpPreviousTransactionEndDateTimestamp = Math.floor(fpPreviousTransactionEndDateAsDate.getTime() / 1000);
    const refundDateTimestamp = Math.floor(refundDateAsDate.getTime() / 1000);

    switch (actionType) {
      case 'one_time_contribution':
        bodyToPatch.contributor_status = 'contributor_once';
        bodyToPatch.loyalty_tier = null;
        break;
      case 'change':
        bodyToPatch.contributor_status = type;
        bodyToPatch.loyalty_tier = loyaltyTier;
        bodyToPatch.newFlipPayTransactionId = fpTransactionId;
        bodyToPatch.flipPayCustomerId = fpCustomerId;
        bodyToPatch.oldEndDate = fpPreviousTransactionEndDateTimestamp;
        bodyToPatch.newStartDate = startDateTimestamp;
        bodyToPatch.newEndDate = endDateTimestamp;
        bodyToPatch.action = 'change';
        break;
      case 'new':
        bodyToPatch.contributor_status = type;
        bodyToPatch.loyalty_tier = loyaltyTier;
        bodyToPatch.endDate = endDateTimestamp;
        bodyToPatch.flipPayTransactionId = fpTransactionId;
        bodyToPatch.flipPayCustomerId = fpCustomerId;
        bodyToPatch.startDate = startDateTimestamp;
        bodyToPatch.action = 'new';
        break;
      case 'cancel':
        bodyToPatch.contributor_status = 'contributor_canceled';
        bodyToPatch.endDate = endDateTimestamp;
        bodyToPatch.action = 'cancel';
        // if the profile tierEndDate is in the past we need to remove the loyalty_tier if it has it, else we respect it until it expires
        if (tierEndDate < Date.now()) {
          bodyToPatch.loyalty_tier = null;
        }
        break;
      case 'refund':
        bodyToPatch.contributor_status = 'contributor_canceled';
        bodyToPatch.loyalty_tier = null;
        bodyToPatch.endDate = refundDateTimestamp;
        bodyToPatch.action = 'cancel';
        break;
      case 'remove_loyalty_tier':
        bodyToPatch.contributor_status = 'contributor_canceled';
        bodyToPatch.loyalty_tier = null;
        break;
      case 'renew':
        bodyToPatch.action = 'renew';
        bodyToPatch.endDate = endDateTimestamp;
        break;
      case 'add_loyalty_tier':
        bodyToPatch.contributor_status = type;
        bodyToPatch.loyalty_tier = loyaltyTier;
        break;
      default:
        break;
    }

    // let's crate the local storage for non recurring contributors
    if (fpRole === 'contributor_once' || fpRole === 'contributor_canceled') {
      const contributorData = storage.get('hp-contributor');
      const fpCreatedDate = new Date(createdAt);
      if (!contributorData || (contributorData && new Date(contributorData.roleCreatedAt) < fpCreatedDate)) {
        document.body.classList.add('is-contributor');
        setNonRecurringContributorStorage(fpRole, createdAt);
      }
    }
    if (actionType) {
      makeSyncMapiCall(bodyToPatch);
    }
    return fpRole;
  }
  return currentRole;
};

const checkMembership = async (profile, hasChanged = false) => {
  const now = new Date();
  const { tierEndDate, roles } = profile;
  const tierEndDateAsDate = new Date(tierEndDate);
  const urlParams = new URLSearchParams(window.location.search);
  const authDone = urlParams.get('hp_auth_done');
  const hasTierEndDatePassed = tierEndDateAsDate && tierEndDateAsDate < now;
  // If user has just authenticated, is on the member page or has a passed tier date then fetch the FP user information
  const shouldFetchFPUserData = authDone === '1' || isMemberPage || hasTierEndDatePassed || hasChanged;
  if (shouldFetchFPUserData) {
    try {
      const fpUserData = await fetchFPUserData(profile);
      let currentRole = roles.find((value) => contributorRoles.includes(value));
      let currentLoyaltyTier = roles.find((value) => loyaltyTiers.includes(value));
      if (fpUserData && fpUserData.data && fpUserData.data.hasPurchase) {
        const { createdAt } = fpUserData.data;
        currentRole = await syncMapi(fpUserData, profile);
        currentLoyaltyTier = fpUserData.data.loyaltyTier;
        return { currentRole, createdAt, currentLoyaltyTier };
      }
      return { currentRole, createdAt: null, currentLoyaltyTier };
    } catch (error) {
      console.error('Error trying to get flip pay user access token', error);
      return null;
    }
  }
  return null;
};

const handlefpMemberCenterAction = async (event) => {
  try {
    const updateEventTypes = ['upgrade', 'downgrade', 'change_renewal_price'];
    const isUpdate = event.detail && event.detail.event_type === 'fp_user_action' && updateEventTypes.includes(event.detail.action_type) && event.detail.action_status === 'processed';
    const isCancel = event.detail && event.detail.event_type === 'fp_user_action' && event.detail.action_type === 'cancel_renewals' && event.detail.action_status === 'processed';
    const isPurchase = event.detail && event.detail.event_type === 'fp_purchase_resolved';
    const fragmentDisplayed = event && event.type === 'fp_user_event' && event.detail && event.detail.event_type === 'fp_fragment_displayed';
    const isRecurringPaymentWebview = window.location.pathname.includes('/payment-recurring/webview');
    let isThankYouWebview = false;
    if (isRecurringPaymentWebview) {
      const parentElements = document.querySelectorAll('.fp-welcome.signed-in .fp-msg.fp-p-app');
      parentElements.forEach((element) => {
        // Check if the element contains the text "please restart the app"
        if (element.textContent.includes('please restart the app')) {
          isThankYouWebview = true;
        }
      });
    }
    const shouldTriggerWebviewSync = fragmentDisplayed && isThankYouWebview;
    if (isPurchase) {
      let contextPageId = 'payment_confirmed';
      if (window.location.pathname.includes('/payment-recurring')) {
        contextPageId = 'payment-recurring_confirmed';
      } else if (window.location.pathname.includes('/member')) {
        contextPageId = 'payment-member_confirmed';
      }

      const payload = { context_page_id: contextPageId, context_page_type: 'utility' };
      window.HUFFPOST.sendClientEvent(window.HUFFPOST.pageview, { ...window.HUFFPOST.tracking, ...payload });
    }
    getProfile()
      .then(async (profile) => {
        if (profile && isValidEmail(profile.email)) {
          const shouldCheckMembership = isCancel || isPurchase || isUpdate || shouldTriggerWebviewSync;
          if (shouldCheckMembership) {
            await checkMembership(profile, true);
          }
        }
      });
  } catch (error) {
    console.error('Error handling member center action:', error);
  }
};

if (isUS) {
  // susbcribe to fp events
  document.body.addEventListener('fp_user_event', handlefpMemberCenterAction);

  getProfile()
    .then(async (profile) => {
      if (profile && isValidEmail(profile.email)) {
        let currentRole = profile.roles.find((value) => contributorRoles.includes(value));
        // let currentLoyaltyTier = profile.roles.find((value) => loyaltyTiers.includes(value));
        let roleCreatedAtDate = new Date();
        const membershipData = await checkMembership(profile);
        if (membershipData) {
          roleCreatedAtDate = membershipData.createdAt;
          currentRole = membershipData.currentRole;
          // currentLoyaltyTier = membershipData.currentLoyaltyTier;
        }
        /* if (webAdFreeRoles.includes(currentLoyaltyTier)) {
          handleMctRefresh();
        } */
        const isContributor = contributorRoles.includes(currentRole);
        if (isContributor) {
          const buttonSupportHuffpost = document.querySelector('.front-support-huffpost__support-button, .cli-support-huffpost__support-button');
          // if logged in we can hide contributor modules based on the mapi role
          switch (currentRole) {
            case 'contributor_monthly':
            case 'contributor_annually':
              // if we have hp-contributor in local storage we should remove it, recurring contributions don't have this
              if (storage.has('hp-contributor')) {
                storage.remove('hp-contributor');
              }
              document.body.classList.add('is-contributor');
              document.body.classList.add(currentRole);
              break;
            case 'contributor_once':
              // When a logged in reader does a one-time contribution, let’s hide the modules for 2 month then show them a tailored message for a year
              handleNonRecurringContributorStatus(currentRole, roleCreatedAtDate, 2);
              if (buttonSupportHuffpost) {
                const itemNameOnce = buttonSupportHuffpost.getAttribute('data-vars-item-name');
                buttonSupportHuffpost.setAttribute('data-vars-item-name', `${itemNameOnce} (Once)`);
                buttonSupportHuffpost.removeAttribute('data-vars-item-name-overwritable');
              }
              break;
            case 'contributor_canceled':
              // When a logged in reader cancels their recurring contribution, let’s hide the modules for 1 month then show them a tailored message for a year
              handleNonRecurringContributorStatus(currentRole, roleCreatedAtDate, 1);
              if (buttonSupportHuffpost) {
                const itemNameOnce = buttonSupportHuffpost.getAttribute('data-vars-item-name');
                buttonSupportHuffpost.setAttribute('data-vars-item-name', `${itemNameOnce} (Canceled)`);
                buttonSupportHuffpost.removeAttribute('data-vars-item-name-overwritable');
              }
              break;
            default:
              document.body.classList.add('non-contributor');
          }
        } else {
          document.body.classList.add('non-contributor');
        }
      }
    });
}
